<script>
import {defineComponent} from 'vue'
import SqDialog from 'components/Common/SqDialog.vue'
import {exportFile} from "quasar";
import JSZip from "jszip";
import {fileManagerService} from "src/services";

export default defineComponent({
  name: 'DownloadModal',

  components: {
    SqDialog
  },

  props: {
    selectedFile: {
      type: Array,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },

  emits: ['saved'],

  data () {
    return {
      isLoading: false,
      fileDownload: {
        downloadActive: false,
        currentFileCount: 0,
        totalFileCount: 0
      }
    }
  },

  computed: {
    downloadProgress() {
      const progress = (this.fileDownload.currentFileCount / this.fileDownload.totalFileCount)
      if(isNaN(progress)) return 0;
      return progress;
    }
  },

  methods: {
    downloadItem() {
      this.fileDownload.downloadActive = true;
      let downloadArray = [];
      const callback = () => {
        if(downloadArray.length > 1) {
          // Multi selection - Create zip
          const zip = new JSZip();
          downloadArray.forEach(file => {
            this.fileDownload.currentFileCount++;
            zip.file(file.meta_data.path, file.content, {base64: true});
          });

          zip.generateAsync({type:"blob"}).then(function(content) {
            const status = exportFile(currentFolderName + ".zip", content)
            if (!status) {
              // browser denied it
              this.$store.dispatch('alert/error', 'browser.downloadRequestBlockedByClient', { root: true });
            }
          });
        } else {
          // Single selection - download only this file
          const file = downloadArray[0]
          if(this.isImage(file)) {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:${file.meta_data.path};base64,${file.content}`;
            downloadLink.download = file.meta_data.path;
            downloadLink.click();
          } else {
            const fileName = file.meta_data.path.split('/').pop();
            const fileExtension = fileName.split('.').pop();
            let fileContent = atob(file.content);
            if(fileExtension.toLowerCase() === "pdf") {
              // PDF file - needs further conversion
              // Convert binary data to a Uint8Array
              const uint8Array = new Uint8Array(fileContent.length);
              for (let i = 0; i < fileContent.length; i++) {
                uint8Array[i] = fileContent.charCodeAt(i);
              }
              // Create Blob from the Uint8Array
              fileContent = new Blob([uint8Array], {type: 'application/pdf'});
            }
            const status = exportFile(fileName, fileContent)
            if (!status) {
              // browser denied it
              this.$store.dispatch('alert/error', 'browser.downloadRequestBlockedByClient', { root: true });
            }
          }
          this.fileDownload.currentFileCount++;
        }
      }
      let itemsProcessed = 0;
      this.fileDownload.currentFileCount = 0;
      const currentFolderName = this.$props.path.substring(this.$props.path.lastIndexOf('/') + 1);
      this.$props.selectedFile.forEach((file, index, array) => {
        if (file.type === "file") {
          fileManagerService.readFile(this.$props.path, file,(data) => {
            if(typeof data !== "undefined") {
              downloadArray.push(data)
              itemsProcessed++;
              if(itemsProcessed === array.length) {
                callback();
                if(this.fileDownload.currentFileCount === this.fileDownload.totalFileCount) {
                  this.fileDownload.downloadActive = false;
                }
              }
            }
          });
        } else {
          // "Folder. Skip."
          itemsProcessed++;
        }
      });
    },
    isImage(file) {
      if (!file) return false

      const allowedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
      let isImage = false;
      allowedImageFormats.forEach(fileFormat => {
        if(typeof file.meta_data.path !== "undefined" && file.meta_data.path.toLowerCase().endsWith("." + fileFormat)) {
          isImage = true;
        }
      });
      return isImage;
    },
    initialize() {
      let files = 0;
      this.$props.selectedFile.forEach((file, index, array) => {
        if (file.type === "file") {
          files++;
        }
        this.fileDownload.totalFileCount = files;
      });
      this.downloadItem();
    },
    resetState() {
      this.fileDownload.downloadActive = false;
      this.fileDownload.currentFileCount = 0;
      this.fileDownload.totalFileCount = 0;
    }
  }
})
</script>

<template>
  <sq-dialog
    type="viewDownload"
    size="lg"
    @hide="resetState"
    @show="initialize"
    :persist="this.fileDownload.downloadActive"
  >
    <template #title>
      {{ $t('browser.download') }}
    </template>

    <template #content>
      <q-linear-progress
          :value="downloadProgress"
          color="primary"
          stripe rounded
          class="q-my-md"
          style="height:1.5rem;"
      >
        <div class="absolute-full flex flex-center">
          <q-badge color="primary" :label="Math.floor(downloadProgress * 100) + '% (' + fileDownload.currentFileCount + '/' + fileDownload.totalFileCount + ')'" />
        </div>
      </q-linear-progress>
    </template>

    <template #actions>
      <q-btn flat
             :label="fileDownload.downloadActive ? $t('general.cancel') : $t('general.close')"
             :color="fileDownload.downloadActive ? 'negative' : 'secondary'"
             v-close-popup
             data-cy="closeModal"
      />
    </template>
  </sq-dialog>
</template>
