<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from 'vuex'
import JsonEditor from 'components/JsonEditor.vue'
import DateDisplay from 'components/DateDisplay.vue'
import SqDialog from 'components/Common/SqDialog.vue'
import LoadingIndicator from "components/LoadingIndicator.vue";
import PDF from "pdf-vue3";
import {fileManagerService} from "src/services";

export default defineComponent({
  name: 'ViewFileModal',

  components: {
    LoadingIndicator,
    SqDialog,
    JsonEditor,
    DateDisplay,
    PDF
  },

  props: {
    path: {},
    file: {},
    base64Encode: {},
    base64Decode: {}
  },

  emits: ['saved'],

  data () {
    return {
      isLoading: false,
      fileContentEdit: '',
      disableEditor: false,
      pdfSrc: null,
      pdfViewerVisible: false
    }
  },

  computed: {
    ...mapGetters('fileManager', [
      'isRefreshing',
      'fileContent'
    ]),

    fileSize() {
      if (!this.fileContent) return ''

      const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let l = 0, n = parseInt(this.fileContent.meta_data.size, 10) || 0;
      while(n >= 1024 && ++l){
        n = n/1024;
      }
      return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },

    fileExtension() {
      if (!this.fileContent) return ''
      return this.fileName.slice(this.fileName.lastIndexOf(".") + 1);
    },

    fileName() {
      return this.fileContent?.meta_data?.path || this.file.basename; // Fallback to file basename
    },

    fileDate() {
      if (!this.fileContent) return ''

      return new Date(this.fileContent.meta_data.timestamp).toISOString();
    },

    isImage() {
      if(!this.fileContent) return false;
      const allowedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
      let isImage = false;
      allowedImageFormats.forEach(fileFormat => {
        if(typeof this.fileName !== "undefined" && this.fileName.toLowerCase().endsWith("." + fileFormat)) {
          isImage = true;
        }
      });
      return isImage;
    },
    isPdf() {
      if(!this.fileContent) return false;
      const isPdf = this.fileName.toLowerCase().endsWith('.pdf');
      this.pdfViewerVisible = isPdf;
      return isPdf;
    }
  },

  methods: {
    ...mapActions('fileManager', ['clearFile']),

    initialize() {
      this.clearFile();
      this.$store.dispatch('fileManager/readFile', {path: this.path, file: this.file});
    },

    resetState() {
      this.isLoading = false;
      this.fileContentEdit = '';
      this.disableEditor = false;
      this.clearFile();
    },

    editFile() {
      this.isLoading = true;
      let fileContent = this.base64Encode(this.fileContentEdit);
      this.$store.dispatch('fileManager/editFile', {path: this.path, file: {name: this.file.basename, content: fileContent}});

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        this.isLoading = false;
        this.$emit('saved');
      } ,1000)
    },

    fetchPDF () {
      fileManagerService.readFile(this.$props.path, this.$props.file,(data) => {
        if (typeof data !== "undefined") {
          this.pdfSrc = data.content;
        }
      });
    }
  },

  watch: {
    fileContent() {
      if (this.fileContent !== null && !this.isPdf) {
        if(this.isImage) this.fileContentEdit = this.fileContent.content;
        else {
          this.fileContentEdit = this.base64Decode(this.fileContent.content, true);
          if(typeof this.fileContentEdit === "undefined") { // Fallback if base64 decoding fails
            this.fileContentEdit = this.fileContent.content;
          }
        }
      } else {
        if (this.fileContent !== null && this.isPdf) {
          this.fetchPDF();
        }
      }
    }
  }
})
</script>

<template>
  <sq-dialog
    type="viewFile"
    size="lg"
    @hide="resetState"
    @show="initialize"
  >
    <template #title>
      {{ file.basename }}
    </template>

    <template #content>
      <div class="q-ml-sm q-mt-md">
        <loading-indicator v-if="!fileContent || isLoading" />
        <template v-else>
          <dl class="app-file-description-list">
            <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.size') }}</dt>
            <dd class="q-mb-sm">
              {{ fileSize }}
            </dd>
            <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.date') }}</dt>
            <dd class="q-mb-sm">
              <date-display v-if="fileDate" :start-time="fileDate" only-date />
            </dd>
            <dt class="app-text-weight-semibold">{{ $t('browser.fileContent.visibility') }}</dt>
            <dd>
              {{ fileContent?.visibility }}
            </dd>
          </dl>
          <template v-if="fileContent && isImage && !isPdf"> <!-- Image-->
            <q-img
              :src="'data:image/'+ fileExtension +';base64,' + fileContent?.content"
              :alt="fileContent?.meta_data.path" :title="fileContent?.meta_data.path"
              fit="scale-down"
              style="max-height: 75vh;"
            />
          </template>
          <template v-if="fileContent && !isImage && isPdf"> <!-- PDF -->
            <loading-indicator v-if="!pdfSrc" />
            <p-d-f v-if="pdfSrc" :src="pdfSrc" class="pdf" />
          </template>
          <template v-if="fileContent && !isImage && !isPdf"> <!-- File -->
            <div class="text-h6 q-mt-md">{{ $t('browser.fileContent.content') }}</div>
            <json-editor
              :lang="fileExtension"
              v-model="fileContentEdit"
              :disabled="disableEditor"
              :no-validation="true"
              wrapped
            />
          </template>
        </template>
      </div>
    </template>

    <template #actions>
      <q-btn flat :label="$t('general.close')" color="secondary" v-close-popup data-cy="closeModal" />
      <q-btn
        v-if="fileContent && !isImage && !isPdf"
        flat
        :disabled="disableEditor || isLoading"
        :label="$t('browser.modal.editFile')"
        type="submit"
        color="primary"
        data-cy="submitModal"
        @click="editFile"
      />
    </template>
  </sq-dialog>
</template>
